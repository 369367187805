import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { store } from './helpers/index';
import { Main } from './main';
import * as serviceWorker from './serviceWorker';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json'
import { history } from './helpers';
import { pageRoutes, GRAPHQL_ERRORS, staticConstants, updateKeyLocalUser, getLocalUserData, MAGIC_NUMBER } from './utils/';
import { subscribeAddOnExpired } from './socket';
import { actionTypes } from './app/auth/redux/types';
JavascriptTimeAgo.addDefaultLocale(en);

const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL });
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    },
  }));
  return forward(operation);
});

subscribeAddOnExpired((err: any, data: any) => {
  const user = getLocalUserData();
  if (data.userId === user._id && !user.isAddOn) {
    updateKeyLocalUser('isAddOn', false);
  }
});

const errorWare = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err && err.extensions && err.extensions.code === GRAPHQL_ERRORS.UNAUTHENTICATED) {
        store.dispatch({ type: actionTypes.RESET_USER_DATA });
        history.push(pageRoutes.LOGIN);
      }
      if (err && err.extensions && err.extensions.code && err.extensions.code.statusCode &&
         err.extensions.code.statusCode === MAGIC_NUMBER.FOURZEROTWO) {
        const user = getLocalUserData();
        if ([staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL].indexOf(user.role) > -1) {
          history.push({
            pathname: `${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}`});
        }
        updateKeyLocalUser('isPaid', false);
        updateKeyLocalUser('isAddOn', false);
      }
      if (err && err.extensions && err.extensions.code === staticConstants.LEARNER_ERROR_CODE) {
        const code = err.extensions.code;
        localStorage.setItem('errorCodeLearner', code);
      } else {
        localStorage.removeItem('errorCodeLearner');
      }
    }
  }

});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  link: errorWare.concat(from([authMiddleware, httpLink])),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions as any,
});

const rootElement = <ApolloProvider client={client}>
  <Provider store={store}>
    {/* <StripeProvider apiKey="pk_live_j2ejQb1rs1GKS40ZyRS3R68r00LfkYcHGN"> */}
    <StripeProvider apiKey="pk_test_C5Xjs4SNWNHzJ9f1JMQACm7k001ipSwWUw">
      <Main />
    </StripeProvider>
  </Provider> </ApolloProvider>;

ReactDOM.render(rootElement, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
